import { Autocomplete } from "@material-ui/lab";
import { isNullOrUndefined, safeInt, safeString } from "../../../Base/Utils";
import { GridInput, SelectInput, TextInput } from "../InputFields";

export default function SelectWarehouseWiseInventory({ gridInputStyle = false, warehouseList, selectedWarehouse, handleSelectionChanged, includeInventory = true, defaultWarehouseId = 0, isAllOption = false }) {
    if (includeInventory) {
        return (
            <>
                <Autocomplete
                    fullWidth
                    size="small"
                    id="warehouseId"
                    name="warehouseId"
                    options={warehouseList}
                    getOptionLabel={(option) => option?.warehouseName}
                    renderOption={(props, option) => {
                        return (
                            <>
                                <span style={{ fontSize: '14px' }}>
                                    {safeString(props?.warehouseName)} <br />
                                    <span style={{ fontSize: '12px' }}>
                                        {!isNullOrUndefined(props) &&
                                            <span>
                                                {`Stock in hand: ${props?.stockInHandQty}`}
                                            </span>
                                        }
                                    </span>
                                </span>
                            </>
                        );
                    }}
                    value={selectedWarehouse}
                    onChange={handleSelectionChanged}
                    renderInput={(props) =>
                        gridInputStyle ?
                            (<GridInput fullWidth
                                name="warehouseName" {...props} label="Warehouse" variant="outlined"
                            />) :
                            <TextInput fullWidth name="warehouseName"  {...props} label="Warehouse" variant="outlined" />
                    }
                />
            </>
        )
    } else {
        return (
            <SelectInput label="Warehouse" name="warehouseId" required
                onChange={(e) => handleSelectionChanged(e, e.target.value)}>
                {
                    isAllOption && <option value="all">All</option>
                }

                {
                    warehouseList?.map((item, index) => {

                        return (
                            <option key={safeInt(item.id)} value={safeInt(item.id)} selected={item.id === defaultWarehouseId}>{item.name}</option>
                        )
                    })
                }
            </SelectInput>
        )
    }
};
