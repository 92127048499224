import { useContext } from 'react';
import { Grid, Button, FormControlLabel, Checkbox, FormControl, FormHelperText } from '@material-ui/core';
import { PageHeader } from '../../UI/Controls/Controls';
import AppIcon from '../../UI/Controls/AppIcons';
import { SelectInput } from '../../UI/Controls/InputFields';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import shallow from 'zustand/shallow';
import useStore from '../../Base/Store';
import { isNullOrUndefined, safeBool, safeInt } from '../../Base/Utils';
import AuthContext from '../../Auth/AuthContext';

const POSvoucherSettings = ({ entityType }) => {
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const authContext = useContext(AuthContext);
    let additionalDetailData = {};
    let fontValue = 8;
    let showHSNTable = true;

    if (!isNullOrUndefined(authContext.settings.additionalDetails) && authContext.settings.additionalDetails !== "") {
        try {
            additionalDetailData = JSON.parse(authContext.settings.additionalDetails);
        } catch (error) {
            console.log("Failed to parse additionalDetails:", error);
            additionalDetailData = {};
        }
    }

    if (!isNullOrUndefined(additionalDetailData.POSinvoiceSettings) && additionalDetailData.POSinvoiceSettings !== "") {
        try {
            const savedFontSize = JSON.parse(additionalDetailData.POSinvoiceSettings);
            fontValue = safeInt(savedFontSize?.tableFontSize);
            if (isNaN(fontValue) || fontValue <= 0) {
                console.log("Invalid tableFontSize found. Resetting to default.");
                fontValue = 8;
            }
            showHSNTable = safeBool(savedFontSize?.showHSNTable);
        } catch (error) {
            console.log("Failed to parse POSinvoiceSettings:", error);
            fontValue = 8;
            showHSNTable = true;
        }
    } else {
        fontValue = 8;
        showHSNTable = true;
    }


    const initialValues = {
        itemSize: fontValue,
        showHSNTable: showHSNTable,
    };

    const submitFormData = (values) => {
        const POSvoucherSettingsData = {
            tableFontSize: safeInt(values.itemSize),
            showHSNTable: values.showHSNTable,
        };
        const POSresponse = {
            POSvoucherSettingsData: POSvoucherSettingsData,
            entityType: entityType,
        };
        setDialogResponse(POSresponse);
        closeDialog();
    };

    const validationSchema = Yup.object({
        itemSize: Yup.number().min(7, 'Minimum is 8px').max(16, 'Maximum is 16px').required('Required'),
        showHSNTable: Yup.boolean().required('Required'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            await submitFormData(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid item xs={12}>
                <PageHeader heading="POS Voucher Settings">
                    <AppIcon name="Settings" color="primary" />
                </PageHeader>
            </Grid>

            {/* Table Font Size Selection */}
            <Grid item xs={12} justifyContent="center" style={{ marginTop: '10px' }}>
                <SelectInput
                    label="Select Item Table Size"
                    name="itemSize"
                    value={formik.values.itemSize}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.itemSize && Boolean(formik.errors.itemSize)}
                    helperText={formik.touched.itemSize && formik.errors.itemSize}
                    required
                >
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                </SelectInput>
            </Grid>

            {/* Show HSN Table Checkbox */}
            <Grid item xs={12} justifyContent="center">
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="showHSNTable"
                                color="primary"
                                checked={formik.values.showHSNTable}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Show HSN Summary"
                    />
                    <FormHelperText style={{ margin: "-10px 30px" }}>This option will include the HSN Summary in the POS Format.</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '21px' }}>
                <Button type="submit" variant="contained" color="primary">
                    Save Settings
                </Button>
            </Grid>
        </form>
    );
};

export default POSvoucherSettings;
