import { Button, Grid, IconButton, Tooltip, ButtonBase } from "@material-ui/core";
import useStyles from "../../styles"
import CustomerGroup from "../Customer/CustomerGroup";
import { AdaptiveButton, LockIcon, PageHeader } from "../UI/Controls/Controls";
import { SelectInput, TextInput, NumberInput, PhoneInput, CurrencyInput, DateInput, TextButtonInput } from "../UI/Controls/InputFields";
import { Autocomplete } from '@material-ui/lab';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useHistory, useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import AppIcon from "../UI/Controls/AppIcons";
import AuthContext from "../Auth/AuthContext";
import WebAPI from "../Base/WebAPI";
import useStore from "../Base/Store";
import axios from "axios";
import DialogWindow from "../UI/Controls/DialogWindow";
import shallow from 'zustand/shallow';
import * as Common from '../Base/Common';
import { safeInt, safeBool, safeString, getConfirmOptions, safeFloat, isNullOrEmpty, setInputFocus, getAdditioanlFieldValue, getISODate, isNullOrUndefined, filterOptions, isEmptyObjectArray } from "../Base/Utils";

import { useConfirm } from "material-ui-confirm";
import { setAuditInformation } from "../Base/Support";
import ImageUpload from "../UI/Controls/ImageUpload";
import { getGSTINInfo } from "../Shared/GST/GSTHelper";
import AddressCard from "../Address/AddressCard";
import Address from "../Address/Address";



export default function Customer({ prefillName = '', referenceId = 0, isModal = false }) {
    const entityName = Common.CUSTOMER_ENTITY;
    const confirm = useConfirm();
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);
    const classes = useStyles();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    var { id } = useParams();
    const stateData = authContext.masterData.filter(md => md.type === 'State');
    const businessTypes = authContext.masterData.filter(md => md.type === 'PartyBusinessType');
    const showAlert = useStore(state => state.showAlert);
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);
    const dialogResponse = useStore(state => state.dialogResponse);
    const [customerGroupList, setCustomerGroupList] = useState([]);
    const [selectedCostCenter, setSelectedCostCenter] = useState(null);
    const [addressId, setAddressId] = useState(0);
    const [shippingAddressList, setShippingAddressList] = useState([]);
    const [selectedAddressID, setSelectedAddressID] = useState(0);
    const [maintainedBalance, setMaintainedBalance] = useState([]);

    const getDefaultStateId = () => {
        let stateId = 0;
        if (!isNullOrUndefined(authContext) && !isNullOrUndefined(authContext.currentSubscriber)) {
            // For retail business types set the statecode by deafult
            if (authContext.currentSubscriber.businessTypeId == "401" || authContext.currentSubscriber.businessTypeId == "404" || authContext.currentSubscriber.businessTypeId == "406") {
                if (!isEmptyObjectArray(authContext.currentSubscriber.addressList)) {
                    stateId = authContext.currentSubscriber.addressList[0].stateId;
                }
            }
        }
        return stateId;
    }

    // const [reload, setReload] = useState(false);
    const [fieldValues, setFieldValues] = useState({
        shortName: '',
        legalName: safeString(prefillName),
        taxIdNumber: '',
        creditDays: 0,
        openingBalance: 0,
        creditLimit: 0,
        openingDate: getISODate(),
        costCenterId: -1,
        costCenterName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        stateId: getDefaultStateId(),
        pinCode: '',
        contactPerson: '',
        contactEmail: '',
        contactPhone: isNaN(safeString(prefillName)) ? '' : safeString(prefillName),
        shippingAddressId: 0,
        shippingAddressLine1: '',
        shippingAddressLine2: '',
        shippingCity: '',
        shippingStateId: 0,
        shippingPinCode: '',
        shippingContactPerson: '',
        shippingContactEmail: '',
        shippingContactPhone: '',
        description: '',
        additionalDetails: '',
        drugLicense20B: '',
        drugLicense21B: '',
        fssaiLicense: '',
        aadhaarNumber: '',
        panNumber: "",
        businessTypeId: 0,
        visitingCard: '',
        visitingCardPreview: '',
        active: true
    });
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const moduleAccess = authContext.moduleAccess(Common.APP_MODULE_Customers);
    const [canExecute, setCanExecute] = useState(false);
    const [auditDetails, setAuditDetails] = useStore(state => [state.auditDetails, state.setAuditDetails], shallow);
    const currencyCode = safeString(authContext.currentSubscriber.currencyCode);



    useEffect(() => {
        setCanExecute((safeInt(id) > 0 && moduleAccess.allowEdit) || (safeInt(id) === 0 && moduleAccess.allowAdd));
        let source = axios.CancelToken.source();
        async function init() {
            if (authContext.token != null) {
                setInputFocus("legalName");
                await fetchData(source);
            }
        }
        init();
        return () => {
            setAuditDetails(null);
            source.cancel();
        };
    }, []);

    const fetchData = async (source) => {
        try {
            setWaiting(true);
            const costCenterReponse = await WebAPI.get('/CostCenter/GetCostCenterList/' + authContext.currentUser.subscriberId, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
            if (!!costCenterReponse.data) {
                if (!!costCenterReponse.data.code && costCenterReponse.data.code > 0) {
                    showAlert(costCenterReponse.data.message, 'error');
                    return;
                }
                setCustomerGroupList(costCenterReponse.data);
            }

            var customer = { ...fieldValues };

            let customerId = 0;
            if (isModal) {
                customerId = safeInt(referenceId);
            } else {
                customerId = safeInt(id);
            }



            if (customerId > 0) {
                const response = await WebAPI.get('/Customer/GetCustomerById/' + customerId, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
                if (!!response.data) {

                    let request = {
                        referenceId: customerId,
                        referenceType: Common.CUSTOMER_ENTITY,
                        bankId: 0,
                        accountNumber: ""
                    }
                    const maintainedBalance = await WebAPI.put('/GL/GetGLAccountOpeningBalanceList/', request, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
                    if (!!maintainedBalance.data) {
                        if (!!maintainedBalance.data.code && maintainedBalance.data.code > 0) {
                            showAlert(maintainedBalance.data.message, 'error');
                            return;
                        }
                        if (maintainedBalance.data.length > 1) {
                            setMaintainedBalance(maintainedBalance.data);
                            const lastBalance = maintainedBalance.data[maintainedBalance.data.length - 1];
                            customer.openingBalance = lastBalance.amount;
                            customer.openingDate = lastBalance.valueDate.slice(0, 10);
                        } else {
                            customer.openingBalance = response.data.openingBalance;
                            customer.openingDate = response.data.openingDate.slice(0, 10);
                        }
                    }
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                        return;
                    }
                    setAuditInformation(response.data, setAuditDetails);


                    customer.shortName = response.data.shortName;
                    customer.legalName = response.data.legalName;

                    customer.taxIdNumber = response.data.taxIdNumber;
                    customer.creditDays = response.data.creditDays;
                    /*  customer.openingBalance = response.data.openingBalance;
                     customer.openingDate = response.data.openingDate.slice(0, 10); */
                    customer.creditLimit = response.data.creditLimit;
                    customer.active = response.data.active;
                    customer.additionalDetails = safeString(response.data.additionalDetails);
                    customer.businessTypeId = safeInt(response.data.businessTypeId);
                    customer.visitingCardPreview = safeString(response.data.visitingCard);
                    if (!isNullOrEmpty(customer.additionalDetails)) {
                        var additionalDetails = JSON.parse(customer.additionalDetails);
                        customer.drugLicense20B = getAdditioanlFieldValue(additionalDetails, 'drugLicense20B');
                        customer.drugLicense21B = getAdditioanlFieldValue(additionalDetails, 'drugLicense21B');
                        customer.fssaiLicense = getAdditioanlFieldValue(additionalDetails, 'fssaiLicense');
                        customer.panNumber = getAdditioanlFieldValue(additionalDetails, 'panNumber');
                        customer.aadhaarNumber = getAdditioanlFieldValue(additionalDetails, 'aadhaarNumber');

                    }
                    if (!!response.data.addressList && response.data.addressList.length > 0) {
                        let billingAddress = response.data.addressList.filter(a => a.addressType === 'Billing');
                        if (!isEmptyObjectArray(billingAddress)) {
                            const address = response.data.addressList[0];
                            setAddressId(address.id);
                            customer.addressLine1 = address.addressLine1;
                            customer.addressLine2 = address.addressLine2;
                            customer.city = address.city;
                            customer.pinCode = address.pinCode;
                            customer.stateId = parseInt(address.stateId);
                            customer.contactPerson = address.contactPerson;
                            customer.contactEmail = address.contactEmail;
                            customer.website = '';
                            customer.contactPhone = address.contactPhone;
                        }
                        let shippingAddress = response.data.addressList.filter(a => a.addressType === 'Shipping' && safeBool(a.active) === true);
                        if (!isEmptyObjectArray(shippingAddress)) {
                            if (isModal && referenceId > 0) {
                                customer.shippingAddressId = shippingAddress[0].id;
                                customer.shippingAddressLine1 = shippingAddress[0].addressLine1;
                                customer.shippingAddressLine2 = shippingAddress[0].addressLine2;
                                customer.shippingCity = shippingAddress[0].city;
                                customer.shippingPinCode = shippingAddress[0].pinCode;
                                customer.shippingStateId = safeInt(shippingAddress[0].stateId);
                                customer.shippingContactPerson = shippingAddress[0].contactPerson;
                                customer.shippingContactEmail = shippingAddress[0].contactEmail;
                                customer.shippingContactPhone = shippingAddress[0].contactPhone;
                            } else {
                                for (let i = 0; i < shippingAddress.length; i++) {
                                    let address = shippingAddress[i];
                                    address.fullAddress = safeString(address.addressLine1) + " " + safeString(address.addressLine2) + " " +
                                        safeString(address.addressLine3) + " " + safeString(address.city) + " " + safeString(address.pinCode);
                                }
                                setShippingAddressList(shippingAddress);
                            }
                        }
                    }
                    if (!!response.data.costCenter && response.data.costCenter.id > 0) {
                        var costCenter = response.data.costCenter;
                        setSelectedCostCenter(costCenter);
                        customer.costCenterId = parseInt(costCenter.id);
                        customer.costCenterName = costCenter.name;
                        customer.costCenterContactPerson = costCenter.contactPerson;
                        customer.costCenterContactEmail = costCenter.costCenterContactEmail;
                        customer.website = '';
                        customer.costCenterContactPhone = costCenter.costCenterContactPhone;
                    }
                    setFieldValues(customer);
                }
            }
        } catch (error) {
            if (WebAPI.isCancel(error) === true) {
                console.log('Request cancelled.');
            } else {
                showAlert('Failed to get customer details. Internal server error.', 'error');
            }
        } finally {
            setWaiting(false);
        }
    }

    const deleteCustomer = async () => {
        confirm(getConfirmOptions('Delete ' + entityName, 'Are you sure, you want to delete ' + entityName + '?'))
            .then(async () => {
                try {
                    setWaiting(true);
                    var response = await WebAPI.put('/' + entityName + '/Delete' + entityName, id, { headers: { "AccessToken": authContext.token } });
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                    } else {
                        showAlert(entityName + ' deleted successfully.', 'success');
                        history.push('/customers');
                    }
                } catch (error) {
                    showAlert(entityName + ' delete failed. Internal server error.', 'error');
                } finally {
                    setWaiting(false);
                }

            }).catch(() => { });
    }

    const submitFormData = async (values, isDelete = false) => {
        try {
            let tempAddressList = null;
            setWaiting(true);
            if (safeBool(isModal)) {
                if (!!values.shippingAddressLine1 && values.shippingAddressLine1.length > 0) {
                    tempAddressList = {
                        id: safeInt(values.shippingAddressId),
                        description: values.description,
                        addressLine1: values.shippingAddressLine1,
                        addressLine2: values.shippingAddressLine2,
                        city: values.shippingCity,
                        stateId: safeInt(values.shippingStateId),
                        pinCode: values.shippingPinCode,
                        contactPerson: values.shippingContactPerson,
                        contactEmail: values.shippingContactEmail,
                        contactPhone: values.shippingContactPhone,
                        addressType: "Shipping",
                        active: true
                    };
                }
            }

            let customerId = 0;
            if (isModal) {
                customerId = safeInt(referenceId);
            } else {
                customerId = safeInt(id);
            }
            var apiUrl = '/Customer/CreateCustomer';
            if (safeInt(customerId) > 0) {
                apiUrl = '/Customer/UpdateCustomer';
            }
            var additionalDetails = [];
            additionalDetails.push({
                fieldName: 'drugLicense20B',
                fieldValue: values.drugLicense20B
            });
            additionalDetails.push({
                fieldName: 'drugLicense21B',
                fieldValue: values.drugLicense21B
            });
            additionalDetails.push({
                fieldName: 'fssaiLicense',
                fieldValue: values.fssaiLicense
            });
            additionalDetails.push({
                fieldName: 'panNumber',
                fieldValue: values.panNumber
            });
            additionalDetails.push({
                fieldName: 'aadhaarNumber',
                fieldValue: values.aadhaarNumber
            });

            let request = {
                "id": safeInt(customerId),
                "subscriberId": authContext.currentUser.subscriberId,
                "shortName": values.shortName,
                "legalName": values.legalName,
                "taxIdNumber": safeString(values.taxIdNumber).toUpperCase(),
                "creditDays": safeInt(values.creditDays),
                "openingBalance": safeFloat(values.openingBalance),
                "creditLimit": safeFloat(values.creditLimit),
                "openingDate": values.openingDate,
                "additionalDetails": JSON.stringify(additionalDetails),
                "businessTypeId": safeInt(values.businessTypeId),
                "visitingCard": isNullOrEmpty(values.visitingCard) ? safeString(values.visitingCardPreview) : safeString(values.visitingCard),
                "active": isDelete ? false : true,
                "addressList": [
                    {
                        "id": safeInt(addressId),
                        "description": values?.description,
                        "addressLine1": values?.addressLine1,
                        "addressLine2": values?.addressLine2,
                        "addressType": 'Billing',
                        "isPrimary": true,
                        "stateId": safeInt(values.stateId),
                        "city": values?.city,
                        "pinCode": values?.pinCode,
                        "contactPerson": values?.contactPerson,
                        "contactEmail": values?.contactEmail,
                        "contactPhone": values?.contactPhone,
                        "website": '',
                        "active": true
                    },
                ]
            };

            if (!isNullOrUndefined(tempAddressList) && safeBool(isModal)) {
                request.addressList.push(tempAddressList);
            }

            request['costCenter'] = {
                id: safeInt(values.costCenterId)
            };
            const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });
            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                if (isModal === true) {
                    if (safeInt(referenceId) > 0) {
                        setDialogResponse(request);
                    } else {
                        setDialogResponse(response.data);
                    }
                    closeDialog();
                } else {
                    history.push("/customers");
                }
                if (safeBool(values.active)) {
                    showAlert('Customer saved successfully.', 'success');
                } else {
                    showAlert('Customer deleted successfully.', 'success');
                }
            }
        } catch (error) {
            console.log(error);
            showAlert(Common.ERROR_INTERNAL_SERVER, "error");
        } finally {
            setWaiting(false);
        }
    }

    const handleDialogClosed = (dialogName, setFieldValue) => {
        try {
            if (dialogResponse != null) {
                switch (dialogName) {
                    case 'addressDialog':
                        let tempAddressList = [...shippingAddressList];
                        tempAddressList.push(dialogResponse);
                        setShippingAddressList(tempAddressList);
                        break;
                    case 'editAdressDialog':
                        let shippingAddress = [...shippingAddressList];
                        if (shippingAddress.length > 0) {
                            let foundIndex = shippingAddress.findIndex(sa => sa.id === dialogResponse.id);
                            if (foundIndex >= 0) {
                                shippingAddress.splice(foundIndex, 1, dialogResponse);
                            }
                        }
                        setShippingAddressList(shippingAddress);

                        break;
                    case 'customerGroupDialog':
                        customerGroupList.push(dialogResponse);
                        setSelectedCostCenter(dialogResponse);
                        setFieldValue('costCenterId', dialogResponse.id);
                        setFieldValue('costCenterName', dialogResponse.name);
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDialogResponse(null);
            setSelectedAddressID(0);

        }
    };

    const openEditHandler = (value) => {
        setSelectedAddressID(value.id);
        openDialog('editAdressDialog');
    }

    const confirmDeletePopup = (values) => {
        confirm(getConfirmOptions('Delete ' + values.description, 'Are you sure, you want to delete address?'))
            .then(async () => {
                try {
                    setWaiting(true);
                    var response = await WebAPI.put('/' + 'Address' + '/DeleteAddress', safeInt(values.id), { headers: { "AccessToken": authContext.token } });
                    if (!!response.data.code && response.data.code > 0) {
                        showAlert(response.data.message, 'error');
                    } else {
                        const updatedShippingAddress = shippingAddressList.filter(sa => sa.id !== values.id);
                        setShippingAddressList(updatedShippingAddress);
                        showAlert('Shipping address deleted successfully.', 'success');
                    }
                } catch (error) {
                    showAlert('Shipping address delete failed. Internal server error.', 'error');
                } finally {
                    setWaiting(false);
                }
            }).catch(() => { });
    }

    return (
        <>
            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                validationSchema={yup.object({
                    shippingContactEmail: yup.string().matches(Common.REGEX_EMAIL_MULTI, 'Invalid email. Please use ";" as separator to enter multiple emails.'),
                    contactEmail: yup.string().matches(Common.REGEX_EMAIL_MULTI, 'Invalid email. Please use ";" as separator to enter multiple emails.'),
                    pinCode: yup.string().matches(Common.REGEX_ALPHA_NUMERIC, 'Invalid value'),
                    shippingPinCode: yup.string().matches(Common.REGEX_ALPHA_NUMERIC, 'Invalid value'),
                    creditDays: yup.string().matches(Common.REGEX_POSTIVE_NUMBER, 'Invalid value.'),
                    taxIdNumber: yup.string().test('exact-length-or-empty', 'GSTIN must be exactly 15 characters', (value) => {
                        if (!isNullOrEmpty(value)) {
                            return value.length === 15 || value.trim() === '';
                        } else {
                            return true;
                        }
                    }),
                    // contactPhone: yup.string().matches(Common.REGEX_PHONE_MULTI, 'Invalid mobile number.'),
                    // shippingContactPhone: yup.string().matches(Common.REGEX_PHONE_MULTI, 'Invalid mobile number.'),
                })}
                onSubmit={
                    async (values) => {
                        await submitFormData(values);
                    }
                }
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <PageHeader heading={(safeInt(id) > 0 || safeInt(referenceId)) ? 'Customer details' : 'New customer'}>
                            <AppIcon name="CustomerIcon" color="primary" />
                        </PageHeader>
                        <div className={classes.page}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Legal name" name="legalName" placeholder="" required
                                        inputProps={{ autoFocus: true, minLength: 1, maxLength: 200 }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Short name" name="shortName" placeholder=""
                                        inputProps={{ maxLength: 100 }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextButtonInput label="Customer GSTIN" name="taxIdNumber" placeholder="Tax Reg. No./GST"
                                        inputProps={{ maxLength: 15, autoFocus: true }} className={classes.toUpper}
                                        buttonTooltip={'Click to validate and fetch GSTIN details.'}
                                        onBlur={() => {
                                            if (!isNullOrEmpty(values?.taxIdNumber) && isNullOrEmpty(values?.addressLine1) && isNullOrEmpty(values?.addressLine2)) {
                                                getGSTINInfo(values.taxIdNumber, stateData, setFieldValue, setWaiting, showAlert, confirm, authContext);
                                            }
                                        }}
                                        buttonIconName={'Check'} handleClick={() => getGSTINInfo(values.taxIdNumber, stateData, setFieldValue, setWaiting, showAlert, confirm, authContext)}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={4}>
                                    <SelectInput label="Type" name="businessTypeId" required>
                                        {
                                            businessTypes.map((item, index) => {
                                                return (
                                                    <option key={safeInt(item.code)} value={safeInt(item.code)}>{item.description}</option>
                                                )
                                            })
                                        }
                                    </SelectInput>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Address line 1" name="addressLine1" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Address line 2" name="addressLine2" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Town/City" name="city" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                {
                                    values.businessTypeId != Common.PartyBusinessTypeExport
                                    &&
                                    <Grid item xs={12} sm={4}>
                                        <SelectInput label="State" name="stateId" required>
                                            <option key={0}></option>
                                            {
                                                stateData.map((item, index) => {
                                                    return (
                                                        <option key={safeInt(item.code)} value={safeInt(item.code)}>{`${item.description} - ${safeString(item.code)}`}</option>
                                                    )
                                                })
                                            }
                                        </SelectInput>
                                    </Grid>
                                }

                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Pincode" name="pinCode" placeholder=""
                                        inputProps={{ maxLength: 6 }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Contact person" name="contactPerson" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Email" name="contactEmail" placeholder="person1@gmail.com; person2@abc.com"
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <PhoneInput label="Phone" name="contactPhone" placeholder=""
                                        inputProps={{ maxLength: 45 }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <CurrencyInput label="Opening balance" name="openingBalance" placeholder="" required currencyCode={currencyCode}
                                        inputProps={{ maxLength: 15, step: 0.01, min: 0, max: Common.CURRENCY_MAX_VALUE }} />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <CurrencyInput label="Credit Limit" name="creditLimit" placeholder="" currencyCode={currencyCode}
                                        inputProps={{ maxLength: 15, step: 0.01, min: 0, max: Common.CURRENCY_MAX_VALUE }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DateInput label="As of date" name="openingDate" placeholder="" required />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <NumberInput label="Credit period (days)" name="creditDays" placeholder=""
                                        inputProps={{ min: 0 }} />
                                </Grid>

                                <Grid container item xs={12} sm={4}>
                                    <Grid item xs={isModal ? 12 : 10} sm={isModal ? 12 : 10}>
                                        <Autocomplete
                                            filterOptions={filterOptions}
                                            size="small"
                                            name="costCenterId"
                                            options={customerGroupList}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option) => option.id}
                                            value={selectedCostCenter}
                                            onChange={(e, value) => {
                                                if (!isNullOrUndefined(value) && safeInt(value.id) > 0) {
                                                    setSelectedCostCenter(value);
                                                    setFieldValue('costCenterId', value.id);
                                                    setFieldValue('costCenterName', value.name);
                                                } else {
                                                    setSelectedCostCenter(null);
                                                    setFieldValue('costCenterId', -1);
                                                    setFieldValue('costCenterName', "");
                                                }
                                            }}
                                            renderInput={(props) =>
                                                <TextInput {...props} name="costCenterName" label="Customer group" className={!isModal ? classes.inputGroupField : null} variant="outlined" />
                                            }
                                        >
                                        </Autocomplete>
                                    </Grid>
                                    <Grid item container xs={2} sm={2} className={`${classes.inputGroup} ${isModal ? classes.hidden : null}`} alignContent="center" justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <Tooltip title="Add new customer group">
                                                <IconButton onClick={(e) => openDialog('customerGroupDialog')} className={classes.mr1}>
                                                    <AppIcon name="Add" color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Drug License 20B" name="drugLicense20B" placeholder="Drug License 20B" />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Drug License 21B" name="drugLicense21B" placeholder="Drug License 21B" />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Fssai License" name="fssaiLicense" placeholder="Fssai License" />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Aadhaar Number" name="aadhaarNumber" placeholder="Fssai License" inputProps={{ maxLength: 12 }} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="PAN Number" name="panNumber" placeholder="Pan Number" inputProps={{ maxLength: 10 }} />
                                </Grid>

                                {maintainedBalance && maintainedBalance.length > 1 && id > 0 ? (
                                    <table style={{
                                        width: '100%',
                                        borderCollapse: 'collapse',
                                        marginTop: '1rem',
                                        margin: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #000'
                                    }}>
                                        <thead>
                                            <tr>
                                                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#F0F8FF', textAlign: 'center' }}>Date</th>
                                                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#F0F8FF', textAlign: 'center' }}>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {maintainedBalance.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.valueDate.slice(0, 10)}</td>
                                                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    null
                                )}


                                <Grid item xs={12} sm={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            id="customerShippingHeader"
                                            className={classes.panelHeader}
                                            expandIcon={<AppIcon name="ExpandMore" />}>
                                            <Typography variant="subtitle1" color="primary" >Shipping address</Typography>
                                        </AccordionSummary>

                                        {isModal ? <>
                                            <Grid container spacing={2} className={classes.p1}>
                                                <Grid item xs={12} sm={4}>
                                                    <TextInput label="Deliver to" name="description" placeholder=""
                                                        inputProps={{}} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextInput label="Address line 1" name="shippingAddressLine1" placeholder=""
                                                        inputProps={{}} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextInput label="Address line 2" name="shippingAddressLine2" placeholder=""
                                                        inputProps={{}} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextInput label="Town/City" name="shippingCity" placeholder=""
                                                        inputProps={{}} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <SelectInput label="State" name="shippingStateId">
                                                        <option key={0} value={0}>{""}</option>
                                                        {
                                                            stateData.map((item, index) => {
                                                                return (
                                                                    <option key={safeInt(item.code)} value={safeInt(item.code)}>{item.description}</option>
                                                                )
                                                            })
                                                        }
                                                    </SelectInput>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextInput label="Pincode" name="shippingPinCode" placeholder=""
                                                        inputProps={{ maxLength: 6 }} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextInput label="Contact person" name="shippingContactPerson" placeholder=""
                                                        inputProps={{}} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextInput label="Email" name="shippingContactEmail" placeholder="person1@gmail.com; person2@abc.com"
                                                        inputProps={{}} />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <PhoneInput label="Phone" name="shippingContactPhone" placeholder=""
                                                        inputProps={{}} />
                                                </Grid>
                                            </Grid>
                                        </> :
                                            shippingAddressList ? <>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        {shippingAddressList.length > 0 ? (
                                                            <Grid container spacing={3}>
                                                                {shippingAddressList.map((address, index) => (
                                                                    <Grid key={index} item xs={12} sm={6} md={3} lg={4} xl={3} style={{ height: "300px", padding: '20px' }}>
                                                                        <AddressCard address={address} openEditHandler={openEditHandler} isSelectedOption={false}
                                                                            isDelete={true} confirmDeletePopup={confirmDeletePopup} />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        ) : (
                                                            <Grid item xs={12}>
                                                                <Typography align="center" color="secondary" variant="h6">Shipping address not found!</Typography>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12} sm={12} className={`${classes.m2}`}>
                                                            <div className={classes.pullCenter}>
                                                                <AdaptiveButton
                                                                    caption="Add address"
                                                                    buttonsize="medium"
                                                                    captionsmall="Add address"
                                                                    iconbutton={<AppIcon name="Add" />}
                                                                    onClick={(e) => openDialog('addressDialog')}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </> : null
                                        }
                                    </Accordion>

                                    <Accordion>
                                        <AccordionSummary
                                            id="customerImageHeader"
                                            className={classes.panelHeader}
                                            expandIcon={<AppIcon name="ExpandMore" />}>
                                            <Typography variant="subtitle1" color="primary" >Customer image</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} alignContent="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    <ImageUpload imageFieldName="visitingCard" imageFieldPreview="visitingCardPreview" setFieldValue={setFieldValue} setWaiting={setWaiting} showAlert={showAlert} values={values} />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item container xs={10} sm={8}>
                                    <Button type="submit" disabled={isSubmitting || waiting || !canExecute}
                                        variant="contained" color="primary" endIcon={!canExecute && <LockIcon />}>
                                        {(safeInt(id) > 0 && !isModal) ? 'Update' : 'Save'}
                                    </Button>

                                    <Button variant="outlined" color="primary" className={classes.ml1} onClick={() => isModal ? closeDialog() : history.goBack()}>{isModal ? 'Cancel' : 'Back'}</Button>
                                </Grid>
                                {(!safeBool(isModal) && safeInt(id) > 0 && canExecute) && moduleAccess.allowDelete &&
                                    <Grid item container xs={2} sm={4} className={classes.pullRight} alignItems="center">
                                        <ButtonBase title="Delete customer" onClick={deleteCustomer}>
                                            <AppIcon color="error" name="Delete" size="large" />
                                        </ButtonBase>
                                    </Grid>
                                }
                            </Grid>

                            <DialogWindow name="customerGroupDialog" open={isDialogOpen}
                                onExited={e => handleDialogClosed('customerGroupDialog', setFieldValue)}
                                width="md" component={<CustomerGroup isModal={true} />} />

                            <DialogWindow name={selectedAddressID > 0 ? "editAdressDialog" : "addressDialog"} open={isDialogOpen}
                                onExited={e => handleDialogClosed(selectedAddressID > 0 ? "editAdressDialog" : "addressDialog", setFieldValue)}
                                width="md" component={
                                    <Address isModal={true} stateData={stateData}
                                        selectedAddress={selectedAddressID} isNewCustomer={(safeInt(id) === 0)} />}
                            />
                        </div>
                    </Form>

                )}
            </Formik>
        </>
    )
};
