import React, { useContext } from 'react';
import { Popover, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UCListButton from '../Controls/UCListButton';
import AuthContext from '../../Auth/AuthContext';
import { APP_MODULE_BankAccounts, APP_MODULE_Bills, APP_MODULE_CompositeItem, APP_MODULE_CreditNotes, APP_MODULE_CustomerGroups, APP_MODULE_Customers, APP_MODULE_DebitNotes, APP_MODULE_DeliveryChallans, APP_MODULE_Employee, APP_MODULE_Expenses, APP_MODULE_Invoices, APP_MODULE_ItemGroup, APP_MODULE_Items, APP_MODULE_Payables, APP_MODULE_PurchaseOrders, APP_MODULE_Quotations, APP_MODULE_Receivables, APP_MODULE_SalesOrders, APP_MODULE_StockInventory, APP_MODULE_Suppliers } from '../../Base/Common';
import { useHistory } from 'react-router';
import NewPaymentLink from '../../Payments/PaymentLinks/NewPaymentLink';
import DialogWindow from '../Controls/DialogWindow';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import ScanQR from '../../../images/QRQuickAdd.png';
import AccountRecive from '../../../images/Accounts-Receivable-quickadd.png';
import AccountPayable from '../../../images/Account-Payable-quickadd.png';



const useStyles = makeStyles((theme) => ({
    popover: {
        backgroundColor: '#232F3E',
        color: '#fff',
        padding: theme.spacing(2),
        width: '63vw', // Increased width for side-by-side layout
        fontFamily: 'roboto',
        position: 'relative',
        overflow: 'hidden'
    },
    triangle: {
        position: 'absolute',
        bottom: '100%', // Position the triangle at the top of the Box
        left: 'calc(50% - 15px)', // Center the arrow horizontally
        width: 0,
        height: 0,
        borderTop: '15px solid #fff', // Size and color of the arrow
        borderRight: '15px solid transparent',
        borderLeft: '15px solid transparent',
    },
    icon: {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1),
    },
    heading: {
        color: "#fff",
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#000',
            borderRadius: '5px',
            cursor: 'pointer',
            color: '#fff'
        },
    },
    appIcon: {
        marginRight: theme.spacing(2),
    },
    subheading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left'
    },
}));

const PopoverExample = ({ anchorEl, handleClose }) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [isDialogOpen, openDialog] = useStore(state => [state.isDialogOpen, state.openDialog], shallow);


    function menuHeadingClickHandler(e) {
        history.push("/" + e.currentTarget.attributes.path.value);
        handleClose();
    }

    const handleDialogClosed = (dialogName) => {
    }

    return (
        <Popover
            style={{ marginLeft: '195px', marginTop: '9px' }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >

            <div className={classes.popover}>
                {/* <div className={classes.triangle}></div> */}
                <Grid container>
                    <Grid item xs={4} xl={3}>
                        <Typography variant="subtitle1" className={classes.heading}>
                            Sales
                        </Typography>
                        <div className={classes.container}>
                            <UCListButton
                                iconColor="#0FCF82"
                                iconName="InvoiceIcon"
                                path="newinvoice"
                                primaryText="Invoice"
                                secondaryText="(Shortcut Alt+N)"
                                disabled={!authContext.moduleAccess(APP_MODULE_Invoices).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                iconColor="#0FCF82"
                                iconName="CustomerIcon"
                                path="customer"
                                primaryText="Customer"
                                secondaryText=""
                                disabled={!authContext.moduleAccess(APP_MODULE_Customers).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                iconColor="#00CED1"
                                iconName="QuotationIcon"
                                path="newquotation"
                                primaryText="Quotation"
                                secondaryText="(Shortcut Alt+Q)"
                                disabled={!authContext.moduleAccess(APP_MODULE_Quotations).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="newsalesorder"
                                iconName="SalesOrder"
                                iconColor="#00CED1"
                                primaryText="Sales Order"
                                disabled={!authContext.moduleAccess(APP_MODULE_SalesOrders).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="newdeliverychallan"
                                iconName="DeliveryChallan"
                                iconColor="#00CED1"
                                primaryText="Delivery challan"
                                disabled={!authContext.moduleAccess(APP_MODULE_DeliveryChallans).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />


                        </div>
                    </Grid>
                    <Grid item xs={4} xl={3}>
                        <Typography variant="subtitle1" className={classes.heading}>
                            Payments
                        </Typography>
                        <div className={classes.container}>
                            <UCListButton
                                path="selectparty/Customer"
                                iconName="Receivable"
                                isImage={true}
                                imageName={AccountRecive}
                                iconColor="#4CAF50"
                                primaryText=" Receive Payment"
                                secondaryText='(Shortcut Alt+I)'
                                disabled={!authContext.moduleAccess(APP_MODULE_Receivables).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="receiveadvance"
                                iconName="RupeeIcon"
                                iconColor="#4CAF50"
                                primaryText="Receive Advance"
                                disabled={!authContext.moduleAccess(APP_MODULE_Receivables).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path=""
                                iconName="RupeeIcon"
                                iconColor="#4CAF50"
                                primaryText="Request Payment"
                                isImage={true}
                                imageName={ScanQR}
                                disabled={!authContext.moduleAccess(APP_MODULE_Receivables).allowAdd}
                                handleButtonClick={(e) => {
                                    handleClose();
                                    openDialog('requestPaymentDialog');
                                }}
                            />

                            <UCListButton
                                path="selectparty/Supplier"
                                iconName="RupeeIcon"
                                isImage={true}
                                imageName={AccountPayable}
                                iconColor="#F24D1D"
                                primaryText="Make Payment"
                                secondaryText='(Shortcut Alt+O)'
                                disabled={!authContext.moduleAccess(APP_MODULE_Payables).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="payadvance"
                                iconName="RupeeIcon"
                                iconColor="#F24D1D"
                                primaryText="Pay Advance"
                                disabled={!authContext.moduleAccess(APP_MODULE_Payables).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                        </div>
                    </Grid>
                    <Grid item xs={4} xl={3}>
                        <Typography variant="subtitle1" className={classes.heading}>
                            Purchase
                        </Typography>
                        <div className={classes.container}>
                            <UCListButton
                                path="newpurchasebill"
                                iconName="PurchaseIcon"
                                iconColor="#1ABCFD"
                                primaryText="Purchase bill"
                                secondaryText=""
                                disabled={!authContext.moduleAccess(APP_MODULE_Bills).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="supplier"
                                iconName="Supplier"
                                iconColor="#1ABCFD"
                                primaryText="Vendor/ Supplier"
                                secondaryText=''
                                disabled={!authContext.moduleAccess(APP_MODULE_Suppliers).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="newpurchaseorder"
                                iconName="PurchaseOrderIcon"
                                iconColor="#00CED1"
                                primaryText="Purchase Order"
                                secondaryText=''
                                disabled={!authContext.moduleAccess(APP_MODULE_PurchaseOrders).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                iconColor="#F44336"
                                iconName="Expense"
                                path="expense"
                                primaryText="Expense"
                                secondaryText="(Shortcut Alt+X)"
                                disabled={!authContext.moduleAccess(APP_MODULE_Expenses).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />


                        </div>
                    </Grid>

                    <Grid item xs={4} xl={3}>
                        <Typography variant="subtitle1" className={classes.heading}>
                            Inventory
                        </Typography>
                        <div className={classes.container}>
                            <UCListButton
                                path="product"
                                iconName="ProductsIcon"
                                iconColor="#A259FF"
                                primaryText="Item / Product"
                                secondaryText=''
                                disabled={!authContext.moduleAccess(APP_MODULE_Items).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="productgroup"
                                iconName="ItemGroup"
                                iconColor="#A259FF"
                                primaryText="Item Group"
                                secondaryText=''
                                disabled={!authContext.moduleAccess(APP_MODULE_ItemGroup).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="compositeItem"
                                iconName="CompositeItem"
                                iconColor="#A259FF"
                                primaryText="Composite Item"
                                disabled={!authContext.moduleAccess(APP_MODULE_CompositeItem).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="stockadjustment"
                                iconName="Adjustments"
                                iconColor="#FF7261"
                                primaryText=" Stock adjustment"
                                disabled={!authContext.moduleAccess(APP_MODULE_StockInventory).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={4} xl={3}>
                        <Typography variant="subtitle1" className={classes.heading}>
                            Masterdata
                        </Typography>
                        <div className={classes.container}>
                            <UCListButton
                                path="bankaccount"
                                iconName="Bank"
                                iconColor="#00CED1"
                                primaryText="Bank Account"
                                disabled={!authContext.moduleAccess(APP_MODULE_BankAccounts).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="CustomerGroup"
                                iconName="CustomerGroup"
                                iconColor="#00CED1"
                                primaryText="Customer Group"
                                disabled={!authContext.moduleAccess(APP_MODULE_CustomerGroups).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="Employee"
                                iconName="Employee"
                                iconColor="#00CED1"
                                primaryText="Employee"
                                disabled={!authContext.moduleAccess(APP_MODULE_Employee).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                            <UCListButton
                                path="expensetype"
                                iconName="Expense"
                                iconColor="#00CED1"
                                primaryText="Expense Type"
                                disabled={!authContext.moduleAccess(APP_MODULE_Expenses).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />

                        </div>
                    </Grid>

                    <Grid item xs={4} xl={3}>
                        <Typography variant="subtitle1" className={classes.heading}>
                            Returns
                        </Typography>
                        <div className={classes.container}>
                            <UCListButton
                                path="newcreditnote"
                                iconName="CreditNote"
                                iconColor="#FF7261"
                                primaryText="Credit Note"
                                disabled={!authContext.moduleAccess(APP_MODULE_CreditNotes).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />
                            <UCListButton
                                path="newdebitnote"
                                iconName="DebitNote"
                                iconColor="#FF7261"
                                primaryText="Debit Note"
                                disabled={!authContext.moduleAccess(APP_MODULE_DebitNotes).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />
                            {/* <UCListButton
                                path="customerrefund/Customer"
                                iconName="RupeeIcon"
                                iconColor="#FF7261"
                                primaryText="Customer Refund"
                                disabled={!authContext.moduleAccess(APP_MODULE_CreditNotes).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            />
                            <UCListButton
                                path="supplierrefund/Supplier"
                                iconName="RupeeIcon"
                                iconColor="#FF7261"
                                primaryText="Suuplier Refund"
                                disabled={!authContext.moduleAccess(APP_MODULE_DebitNotes).allowAdd}
                                handleButtonClick={(e) => menuHeadingClickHandler(e)}
                            /> */}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <DialogWindow name="requestPaymentDialog" open={isDialogOpen}
                onExited={e => handleDialogClosed('requestPaymentDialog')}
                width="sm" component={<NewPaymentLink paymentData={null} isCustomerPayment={true} />} />

        </Popover>
    );
};

export default PopoverExample;
