import { INVOICE_FORMAT_PROFESSIONAL, TAX_REG_UNREGISTERED } from "../../../Base/Common";
import { safeFloat, getLocaleDate, safeBool, formatToCurency, isNullOrEmpty, safeInt, safeString, getISODate, isNullOrUndefined, ifNullOrEmpty, getAmountInWords } from "../../../Base/Utils";
import { isSalesVoucher } from "../../../Orders/OrderItemHelper";

export function getTotalRow(columnDefinition, voucherData, printFormatCustomSettings) {
    let row = [];
    let amountValue = 0;
    columnDefinition.map((col, colIndex) => {
        switch (col.field) {
            case "quantity":
                if (voucherData.totalQuantity > 0) {
                    row.push({ text: voucherData.totalQuantity, bold: true, alignment: 'center', fontSize: printFormatCustomSettings.itemSize });
                } else {
                    row.push({ text: '', bold: true, alignment: 'center', fontSize: printFormatCustomSettings.itemSize });
                }
                break;
            case "quantityDescription":
                if (voucherData.quantityDescriptionTotal > 0) {
                    row.push({ text: voucherData.quantityDescriptionTotal, bold: true, alignment: 'right', fontSize: printFormatCustomSettings.itemSize });
                } else {
                    row.push({ text: '', bold: true, alignment: 'center', fontSize: printFormatCustomSettings.itemSize });
                }
                break;
            case "taxableAmount":
                amountValue = safeFloat(voucherData.orderTotal);
                if (safeBool(col.blankIfZero)) {
                    if (amountValue !== 0) {
                        amountValue = formatToCurency(amountValue);
                    } else {
                        amountValue = '0.00';
                    }
                } else {
                    amountValue = amountValue.toFixed(2);
                }
                row.push({ text: amountValue, bold: true, alignment: 'right', fontSize: printFormatCustomSettings.itemSize });
                break;
            case "taxAmount":
                amountValue = safeFloat(voucherData.taxAmount);
                if (safeBool(col.blankIfZero)) {
                    if (amountValue !== 0) {
                        amountValue = formatToCurency(amountValue);
                    } else {
                        amountValue = '0.00';
                    }
                } else {
                    amountValue = amountValue.toFixed(2);
                }
                row.push({ text: amountValue, bold: true, alignment: 'right', fontSize: printFormatCustomSettings.itemSize });
                break;
            case "lineTotal":
                amountValue = safeFloat(voucherData.grandTotal);
                if (safeBool(col.blankIfZero)) {
                    if (amountValue !== 0) {
                        amountValue = formatToCurency(amountValue);
                    } else {
                        amountValue = '0.00';
                    }
                } else {
                    amountValue = amountValue.toFixed(2);
                }
                row.push({ text: amountValue, bold: true, alignment: 'right', fontSize: printFormatCustomSettings.itemSize });
                break;
            case "description":
                row.push({ text: 'Total', bold: true, alignment: 'right', fontSize: printFormatCustomSettings.itemSize });
                break;
            default:
                row.push({ text: '' });
                break;
        }
    });
    return row;
}
export function buildItemsTable(columnDefinition, voucherData, tableData, hasHeader = true, printFormatCustomSettings) {
    var columnWidths = [];
    var columnAlignments = [];
    var columnHeaders = [];

    /*  columnDefinition.sort((a, b) => a.sequence - b.sequence); */


    if (!isNullOrUndefined(columnDefinition) && columnDefinition.length > 0) {
        columnDefinition.map(def => {
            columnWidths.push(def.width);
            columnAlignments.push((!!def.align ? def.align : 'left'));
            if (hasHeader === true) {
                columnHeaders.push({ text: def.title, bold: true, alignment: def.align, fontSize: printFormatCustomSettings.itemSize });
            }
        });
    }
    var tableBody = [];
    if (hasHeader === true) {
        tableBody.push(columnHeaders);
    }
    let isMiscellaneous = false;
    let totalQuantity = 0
    let quantityDescriptionTotal = 0;
    if (!isNullOrUndefined(tableData) && tableData.length > 0) {
        tableData.map((dataItem, rowIndex) => {
            let row = [];
            isMiscellaneous = safeString(dataItem?.productType) === 'Miscellaneous';
            if (dataItem['productType'] === 'Goods') {
                totalQuantity += dataItem['quantity'];
            }
            if (dataItem['productType'] === 'Goods' && safeBool(printFormatCustomSettings.showTotalQuantityDescription)) {
                quantityDescriptionTotal += safeInt(dataItem['quantityDescription']);
            }
            columnDefinition.map((col, colIndex) => {
                if (col.field === 'index') {
                    row.push({ text: rowIndex + 1, alignment: col.align, bold: !!col.bold ? col.bold : false, fontSize: printFormatCustomSettings.itemSize });
                } else {
                    if (col.type === 'currency') {
                        let amountValue = safeFloat(dataItem[col.field]);
                        if (safeBool(col.blankIfZero)) {
                            if (amountValue !== 0) {
                                amountValue = formatToCurency(amountValue);
                            } else {
                                amountValue = '0.00';
                            }
                        } else {
                            amountValue = amountValue.toFixed(2);
                        }
                        if (isMiscellaneous && safeFloat(dataItem[col.field]) === 0) {
                            amountValue = '';
                        }
                        row.push({ text: amountValue, alignment: col.align, fontSize: printFormatCustomSettings.itemSize, bold: !!col.bold ? col.bold : false });
                    } else if (col.type === 'date') {
                        row.push({ text: getLocaleDate(dataItem[col.field]), alignment: col.align, fontSize: printFormatCustomSettings.itemSize, bold: !!col.bold ? col.bold : false });
                    } else {
                        let colValue = dataItem[col.field];
                        if (safeBool(col.blankIfNegative)) {
                            if (colValue <= 0) {
                                colValue = '--';
                            }
                        }
                        if (!isNullOrUndefined(col.tertiaryField)) {
                            let tempColValue = colValue;
                            if (!isNullOrUndefined(col.secondaryField) && !isNullOrEmpty(dataItem[col.secondaryField])) {
                                tempColValue = getDescInfoText(colValue, dataItem[col.secondaryField], false, printFormatCustomSettings.itemSize, col.align)
                            }
                            if (!isNullOrUndefined(col.tertiaryField) && !isNullOrEmpty(dataItem[col.tertiaryField])) {
                                tempColValue = getDescInfoText(tempColValue, 'IMEI/Sl.#:' + dataItem[col.tertiaryField], false, printFormatCustomSettings.itemSize, col.align)
                            }
                            row.push({ text: tempColValue, alignment: col.align, fontSize: printFormatCustomSettings.itemSize, bold: !!col.bold ? col.bold : false });
                        } else {
                            if (!isNullOrUndefined(col.secondaryField)) {
                                row.push(getDescInfoText(colValue, dataItem[col.secondaryField], false, printFormatCustomSettings.itemSize, col.align));
                            } else {
                                row.push({ text: colValue, alignment: col.align, fontSize: printFormatCustomSettings.itemSize, bold: !!col.bold ? col.bold : false });
                            }
                        }
                    }
                }
            });
            tableBody.push(row);
        });
    }
    voucherData.totalQuantity = totalQuantity;
    voucherData.quantityDescriptionTotal = quantityDescriptionTotal;
    tableBody.push(getEmptyRow(columnDefinition));
    tableBody.push(getTotalRow(columnDefinition, voucherData, printFormatCustomSettings));
    var table = {
        widths: columnWidths,
        headerRows: hasHeader === true ? 1 : 0,
        body: tableBody
    };

    return table;

}

function getEmptyRow(columnDefinition) {
    let row = [];
    columnDefinition.map((col, colIndex) => {
        row.push({ text: '.', fontSize: 1 })
    });
    return row;
}

export function getWaterMark(settings) {
    const watermarkLabel = safeString(settings?.watermarkLabel);
    const waterMarkAngle = safeInt(settings?.waterMarkAngle);
    if (!isNullOrEmpty(watermarkLabel)) {
        return { text: !isNullOrEmpty(watermarkLabel) ? "   " + watermarkLabel + "   " : "", opacity: 0.5, color: '#8cb4cd', angle: waterMarkAngle };
    }
    return null;
}

export function getPDFDocumentInfo(title, subject, keywords) {
    return {
        title: title,
        creator: 'www.ezybill.in',
        producer: 'ezybill',
        creationDate: getISODate(),
        author: 'EmerSys LLP',
        subject: subject,
        keywords: keywords,
    }
}

export function getPDFStyles(format, printFormatCustomSettings = null) {
    let styles = {};
    switch (format) {
        case INVOICE_FORMAT_PROFESSIONAL:
            styles = {
                borderColor: {
                    style: safeInt(printFormatCustomSettings.borderColor, "red")
                },
                fontRegular: {
                    fontSize: safeInt(printFormatCustomSettings?.fontRegular, 12)
                },
                borderThickness: {
                    style: safeInt(printFormatCustomSettings.borderThickness, 1)
                },
                fontBold: {
                    fontSize: 12
                },
                fontMainHeading1: {
                    fontSize: safeInt(printFormatCustomSettings?.fontMainHeading1, 18),
                    color: ifNullOrEmpty(printFormatCustomSettings?.fontMainHeading1Color, 'black')
                },
                fontMainHeading2: {
                    fontSize: safeInt(printFormatCustomSettings?.fontMainHeading2, 16),
                    color: ifNullOrEmpty(printFormatCustomSettings?.fontMainHeading2Color, 'black')
                },
                fontSubHeading1: {
                    fontSize: safeInt(printFormatCustomSettings?.fontSubHeading1, 14),
                    color: ifNullOrEmpty(printFormatCustomSettings?.fontSubHeading1Color, 'black')
                },
                fontSubHeading2: {
                    fontSize: safeInt(printFormatCustomSettings?.fontSubHeading2, 13),
                    color: ifNullOrEmpty(printFormatCustomSettings?.fontSubHeading2Color, 'black')
                },
                nestedTable: {
                    margin: [0, 0, 0, 0],
                    fontSize: 12,
                },
                fontHyperLink: {
                    fontSize: safeInt(printFormatCustomSettings?.fontHyperLink, 12),
                    color: ifNullOrEmpty(printFormatCustomSettings?.fontHyperLinkColor, 'black'),
                    decoration: 'underline'
                },
                backgroundFillColor: {
                    margin: [0, 0, 0, 0],
                    fillColor: ifNullOrEmpty(printFormatCustomSettings?.fillColor, 'lightsteelblue')
                },
                noBorder: {
                    margin: [0, 0, 0, 8]
                },
                itemsTable: {
                    margin: [0, 0, 0, 0],
                    fontSize: 10,
                }
            }
            break;

        default:
            break;
    }

    return styles;
}


export function getPDFStateName(stateId, stateList) {
    if (!isNullOrUndefined(stateId) && stateId != "") {
        if (safeInt(stateId) > 0) {
            var stateName = stateList.find(s => safeInt(s.code) == safeInt(stateId)).description;
            if (!!stateName) {
                return stateName;
            }
        } else {
            return "";
        }
    }
    return stateId;
}

export function getPDFLabelText(label, text, boldData = true, fontSize = 9, align = 'center') {
    return { text: [label, { text: text, bold: boldData }], fontSize: fontSize, alignment: align };
}

export function getLabelText(label, text, boldData = true, boldLabel = false, align = 'left', textStyle = 'fontRegular') {
    return { text: [label + ' ', { text: text, bold: boldData, style: textStyle }], style: textStyle, alignment: align, bold: boldLabel };
}

export function getPartyName(party, entityType) {
    if (isSalesVoucher(entityType)) {
        return party.name;
    }
    return party.legalName;
}

export function getPlaceOfSupply(voucherData, partyAddress, stateList) {
    let placeOfSupply = "";
    if (!isNullOrUndefined(voucherData) && !isNullOrUndefined(partyAddress) && !isNullOrUndefined(stateList)) {
        placeOfSupply = `${getPDFStateName(ifNullOrEmpty(voucherData.placeOfSupply, partyAddress.stateId), stateList)} (${ifNullOrEmpty(voucherData.placeOfSupply, partyAddress?.stateId)})`;

    }
    return placeOfSupply;
}

export function getFullAddressString(partyAddress, stateList) {
    let fullAddress = "";
    if (!isNullOrUndefined(partyAddress)) {
        fullAddress = `${safeString(partyAddress.addressLine1)} ${safeString(partyAddress.addressLine2)} ${safeString(partyAddress.city)} ${getPDFStateName(safeString(partyAddress.stateId), stateList)} ${safeString(partyAddress.pinCode)}`;
    }
    return fullAddress;
}

function getDescInfoText(description, infoText, boldInfo = false, fontSize = 9, align = 'left') {
    return { text: [description, { text: "\n" + infoText, bold: boldInfo }], fontSize: fontSize, alignment: align, bold: true };
}

export function getPDFQRCode(bankDetails, width = 81, align = '') {
    if (!isNullOrUndefined(bankDetails)) {
        var qrCode = ifNullOrEmpty(bankDetails.qrCode, "");
        if (qrCode === "") {
            return (
                [
                    { text: '' }
                ]
            )
        } else {
            return (
                [
                    {
                        image: safeString(bankDetails.qrCode),
                        width: width,
                        margin: [0, 2, 2, 0],
                        alignment: align
                    }
                ]
            );
        }
    } else {
        return (
            [
                { text: '' }
            ]
        )
    }

}
export function getGSTINNumber(printData) {
    let taxIdNumberData = "";
    let textIdLable = ""

    if (printData.subscriber.taxRegistrationTypeId === TAX_REG_UNREGISTERED) {
        return;
    } else {
        if (!isNullOrUndefined(printData.subscriber.taxIdNumber)) {
            taxIdNumberData = printData.subscriber.taxIdNumber;
        }
        textIdLable = "GSTIN: "
    }

    return {
        text: [
            { text: textIdLable + ' ', bold: false, style: "fontRegular" },
            { text: taxIdNumberData, bold: true, style: "fontRegular" }
        ],
        style: "fontRegular",
        alignment: "left",
        bold: true
    };
}
