import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    boldInput: {
        fontSize: '13px',
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
    },
    shortenString: {
        whiteSpace: "nowrap",
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    popperFullWidth: {
        minWidth: "fit-content",
        zIndex: 9999,
        marinLeft: theme.spacing(1),
        marinRight: theme.spacing(1),
    },
    infoArea: {
        border: '1px solid lightsteelblue',
        backgroundColor: '#F7F7F7',
        borderRadius: '5px',
        margin: theme.spacing(1),
        // boxShadow: '2px 2px 2px 2px #f0f0f0',
    },
    option: {
        "&:hover": {
            backgroundColor: theme.palette.secondary.highlight + "!important",
            transform: 'scale(1.01)',
            transition: 'transform 0.3s',
        }
    },
    contextItemHover: {
        "&:hover": {
            backgroundColor: "#DBE9FA !important",
            transform: 'scale(1.01)',
            transition: 'transform 0.3s',
        }
    },
    submitBar: {
        position: 'fixed',
        /*  left: 210, */
        bottom: 0,
        borderRadius: '5px',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: 'white',
        zIndex: 999
    },
    submitBarPopup: {
        position: 'fixed',
        left: 8,
        bottom: 0,
        borderRadius: '5px',
        padding: theme.spacing(2),
        backgroundColor: 'white',
        zIndex: 999
    },
    imageFit: {
        height: 'auto',
        maxWidth: '100%'
    },
    auditTable: {
        width: '100%'
    },
    gstr3bTable: {
        border: '1px solid black',
        padding: '8px',

    },
    textarea: {
        resize: "vertical"
    },
    pricingStriked: {
        textDecoration: "line-through"
    },
    pricingOffer: {
        color: theme.palette.success.main,
    },
    packageCard: {
        maxWidth: 345
    },
    editableLabel: {
        color: theme.palette.success.main,
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        cursor: 'pointer'
    },
    tableContainer: {
        maxWidth: '100vw',
        overflowX: 'auto',
    },
    centerContainer: {
        height: '100%',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    avatarAddIcon: {
        backgroundColor: theme.palette.secondary.dark + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
        width: '32px !important',
        height: '32px !important'
    },
    avatarAddIconSecondary: {
        backgroundColor: theme.palette.secondary.main + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
        width: '32px !important',
        height: '32px !important'
    },
    avatarAddIconSuccess: {
        backgroundColor: theme.palette.success.dark + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
        width: '32px !important',
        height: '32px !important'
    },

    iframeContainer: {
        position: 'relative',
        width: '100%',
        paddingBottom: '56.25%',
        height: 0,
        '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        },
    },
    tncText: {
        color: theme.palette.error.dark,
        fontSize: '0.83em',
        textAlign: 'center',
        marginLeft: '16px',
        marginRight: '16px'
    },
    mutedText: {
        color: 'steelblue',
        fontStyle: 'italic',
        fontSize: '0.83em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    mutedTextList: {
        color: 'darkgray',
        fontStyle: 'italic',
        fontSize: '0.81em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    mutedTextBtn: {
        color: 'lightgray',
        fontStyle: 'italic',
        fontSize: '0.81em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    infoText: {
        color: theme.palette.info.dark,
        fontStyle: 'italic'
    },
    warningText: {
        color: theme.palette.warning.main,
        // fontStyle: 'italic',
        fontSize: '0.8em',
    },
    toUpper: {
        textTransform: 'uppercase',
        '& .MuiInputBase-input': {
            textTransform: 'uppercase'
        }
    },
    avatarError: {
        border: 'solid 2px',
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.background.paper + ' !important'
    },
    avatarWarning: {
        border: 'solid 1px',
        borderColor: '#ff9800',
        backgroundColor: theme.palette.background.paper + ' !important',
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontSize: '1em',
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        '&:hover': {
            textDecoration: 'underline',
            transform: 'scale(1.05)',
            // transition: '0.1s'
        },
    },
    linkButton: {
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontSize: '1em',
        '&:hover': {
            textDecoration: 'underline',
            transform: 'scale(1.05)',
            // transitionDuration: '0.1s'
        },
    },
    infoDrawerTopToolBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        minWidth: '360px',
        backgroundColor: theme.palette.primary.dark
    },
    infoDrawerTopToolBarFullWidth: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        minWidth: '100vw',

        backgroundColor: theme.palette.primary.dark,
        /*  position: 'fixed',
         top: 0,
         zIndex: 1000, */
    },
    QuickAddInfoDarawer: {
        width: '70vw',
    },


    infoDrawerTitle: {
        marginLeft: theme.spacing(2),
        color: '#ffffff'
    },
    loadingBackdrop: {
        zIndex: '1800 !important',
        color: '#FFFFFF',
        backgroundColor: 'rgba(0,0,0,0.8)'
    },
    hidden: {
        display: 'none'
    },
    quickAddMenu: {
        cursor: "pointer",
        padding: "5px",
        "& .MuiListItemIcon-root": {
            minWidth: '36px',
        },
    },
    dialogRoot: {
        "& .MuiDialog-scrollPaper": {
            alignItems: 'flex-start !important',
        },
        "& .MuiDialogTitle-root": {
            margin: '0px 10px',
            padding: '0px',
        },
    },
    dialogCompact: {
        "& .MuiDialogContent-root": {
            padding: '0px',
        }
    },
    topScrollPaper: {
        // alignItems: 'flex-start',      
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    dashboardTableHeader: {
        backgroundColor: '#DBE9FA',
        // fontSize: '12px !important',
        fontWeight: 'bold !important',
        padding: '0px !important',
    },
    dashboardTableSubHeader: {
        fontSize: '12px !important',
        fontWeight: 'bold !important',
        padding: '0px !important',
    },
    successAvatar: {
        backgroundColor: theme.palette.success.main + " !important",
        color: '#FFF !important',

    },
    infoAvatar: {
        backgroundColor: theme.palette.info.main + " !important",
        color: '#FFF !important',
    },
    list: {
        backgroundColor: theme.palette.secondary.light,
        border: "1px solid",
        borderColor: theme.palette.primary.dark,
        borderRadius: "10px",
        cursor: "pointer"
    },
    iconLabel: {
        // Aligns the content of the button vertically.
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    uploadFileInput: {
        display: 'none',
    },
    panelHeader: {
        backgroundColor: theme.palette.secondary.light,
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginTop: 0,
        marginBottom: -1,
        paddingBottom: 0,
        paddingTop: 0,
    },
    leftAlignDialogActions: {
        justifyContent: 'space-between'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'flex-end',

    },
    dialogContent: {
        paddingTop: '0px',
        marginTop: '0px',
        marginBottom: theme.spacing(1),
    },
    noSpacing: {
        padding: '0px !important',
        margin: '0px !important',
        "& .MuiIconButton-root": {
            padding: '0px !important'
        }
    },
    inputGroup: {
        borderTop: '1px solid #a094b7', borderBottom: '1px solid #a094b7', borderRight: '1px solid #a094b7', margin: '0px',
        borderRadius: '0px 5px 5px 0px',
        // lineHeight: '272%',
        minHeight: '40px',
        maxHeight: '40px',
    },
    inputGroupField: {
        [`& fieldset`]: {
            borderRadius: '5px 0px 0px 5px',
        },
    },
    loginImage: {
        position: 'absolutue',
        top: '50%',
        transform: 'translateY(100%)',
    },
    loginCard: {
        backgroundColor: 'rgba(255,255,255, 100)',
        margin: '0',
        borderRadius: '10px',
        border: '2px solid steelblue',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-53%)',
    },
    loginBackground: {
        background: '#232F3E',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative', // Enable absolute positioning for child elements
    },
    circleLeft: {
        position: 'absolute',
        top: '18%',
        left: '-90px',
        width: '270px',
        height: '270px',
        backgroundColor: 'rgba(11, 92, 255, 0.2)',
        borderRadius: '50%',
        // animation: '$moveLeftRight 6s ease-in-out infinite',
        zIndex: 1,
    },
    circleRight: {
        position: 'absolute',
        bottom: '18%',
        right: '-90px',
        width: '270px',
        height: '270px',
        backgroundColor: 'rgba(37, 211, 102, 0.2)',
        borderRadius: '50%',
        // animation: '$moveLeftRightReverse 6s ease-in-out infinite',
        zIndex: 1,
    },
    '@keyframes moveLeftRight': {
        '0%': { transform: 'translateX(0)' },
        '50%': { transform: 'translateX(50px)' },
        '100%': { transform: 'translateX(0)' },
    },
    '@keyframes moveLeftRightReverse': {
        '0%': { transform: 'translateX(0)' },
        '50%': { transform: 'translateX(-50px)' },
        '100%': { transform: 'translateX(0)' },
    },
    itemToolBar: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: '1px solid #0B3966',
        borderRadius: '5px',
        backgroundColor: theme.palette.secondary.light
    },
    dateInput: {
        paddingLeft: '6px',
        paddingRight: '0px',
    },
    dateInputBold: {
        fontSize: '13px',
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
        paddingLeft: '6px',
        paddingRight: '0px',
    },
    numberInput: {
        paddingRight: '2px',
        paddingLeft: '5px',
    },
    numberInputBold: {
        fontSize: '13px',
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
        paddingRight: '2px',
        paddingLeft: '5px',
    },
    quantityInput: {
        paddingRight: '5px',
        paddingLeft: '5px',
    },
    quantityInputBold: {
        fontSize: '13px',
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
        paddingRight: '5px',
        paddingLeft: '5px',
    },
    formHeader: {
        display: 'flex',
        justify: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginLeft: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    rowDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        backgroundColor: '#f5f5f5',
        borderRadius: '5px',
    },
    page: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        boxShadow: '5px 5px 5px 5px #f0f0f0',
        borderRadius: '5px',
    },
    adaptiveButton: {
        [theme.breakpoints.down('md')]: {
            border: `1px solid white`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px',
            minWidth: '36px',
            color: theme.palette.success.main
        },
        fontWeight: 'bold',

    },
    // Genric alignments
    pullRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            padding: '0px !important',
        },
    },
    pullRightOrderList: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    pullLeft: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '0px !important',
        padding: '0px !important',
    },
    pullCenter: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0px !important',
        padding: '0px !important',
    },
    pullEvenly: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px !important',
        padding: '0px !important',
    },
    pullAround: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems : 'center',
        margin: '0px !important',
        padding: '0px !important',
    },
    // Generic paddings
    p1: {
        padding: theme.spacing(1),
    },
    p2: {
        padding: theme.spacing(2),
    },
    pl1: {
        paddingLeft: theme.spacing(1),
    },
    pr1: {
        paddingRight: theme.spacing(1),
    },
    pt1: {
        paddingTop: theme.spacing(1),
    },
    pb1: {
        paddingBottom: theme.spacing(1),
    },

    pl2: {
        paddingLeft: theme.spacing(2),
    },
    pr2: {
        paddingRight: theme.spacing(2),
    },
    pt2: {
        paddingTop: theme.spacing(2),
    },
    pb2: {
        paddingBottom: theme.spacing(2),
    },

    // Generic margins
    m1: {
        margin: theme.spacing(1),
    },
    m2: {
        margin: theme.spacing(2),
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mr1: {
        marginRight: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mb1: {
        marginBottom: theme.spacing(1),
    },
    mbt1: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    mlr1: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    ml2: {
        marginLeft: theme.spacing(2),
    },
    mr2: {
        marginRight: theme.spacing(2),
    },
    mt2: {
        marginTop: theme.spacing(2),
    },
    mb2: {
        marginBottom: theme.spacing(2),
    },
    laptopOnly: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    desktopOnly: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobileOnly: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    primaryLight: {
        color: theme.palette.primary.light, // or theme.palette.primary.main
    },
    secondaryLight: {
        color: theme.palette.secondary.light, // or theme.palette.primary.main
    },
    infoColor: {
        color: theme.palette.info.main // or theme.palette.primary.main
    },
    errorColor: {
        color: theme.palette.error.light // or theme.palette.primary.main
    },
    errorButton: {
        backgroundColor: theme.palette.error.main,
        color: '#FFF',
    },
    loginButton: {
        backgroundColor: theme.palette.secondary.main + " !important",
        color: '#FFF !important',
    },
    successButtonText: {
        // backgroundColor: "#f7f7f7 !important",
        color: theme.palette.success.dark + " !important",
        // '&:hover': {
        //     backgroundColor: theme.palette.success.dark,
        //     color: '#FFF'
        // },
    },
    successButton: {
        backgroundColor: theme.palette.success.main + " !important",
        color: '#FFF !important',
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
            color: '#FFF'
        },
    },

    successButtonOutlined: {
        border: `1px solid ${theme.palette.success.main} !important`,
        color: `${theme.palette.success.main} !important`,
        '&:hover': {
            backgroundColor: theme.palette.success.main,
            color: '#FFF !important'
        },
    },
    addItemButton: {
        color: '#FFF !important',
        backgroundColor: theme.palette.secondary.main + " !important",
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: '#FFF !important'
        },
        // padding: 0
    },
    successColor: {
        color: theme.palette.success.main + " !important", // or theme.palette.primary.main
    },
    dangerColor: {
        color: theme.palette.error.main,
    },
    shadowContainer: {
        backgroundColor: theme.palette.grey,
        boxShadow: '5px #f0f0f0',
        borderRadius: '10px',
    },
    formContainer: {
        padding: theme.spacing(2),
    },
    formContent: {
        flexGrow: 1,
        // marginTop: theme.spacing(1),
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)'
    },

    shadowEffect: {
        boxShadow: '2px 2px 2px 2px lightgrey'
    },
    container: {
        '& > *': {
            margin: theme.spacing(1),
        },
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    fullWidthCol: {
        width: '100%',
    },
    buttonWidth: {
        padding: '10px',
        width: '100%'
    },
    root: {
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        // "& .MuiButton-root": {
        //     minWidth: '36px',
        // },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    orderItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#f5f5f5', // Hover background color
        },
    },
    productInfo: {
        width: '30%',
    },
    // quantityInput: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     width: '20%',
    // },
    qtyButton: {
        backgroundColor: '#e0e0e0',
        border: 'none',
        padding: '8px',
        cursor: 'pointer',
    },
    qtyInput: {
        width: '50px',
        textAlign: 'center',
    },
    priceInput: {
        width: '25%',
    },
    removeButton: {
        width: '10%',
    },
    footerQuickAddOrder: {
        padding: theme.spacing(2),
        width: '70vw',
        position: 'fixed',
        bottom: 0,
        boxShadow: '0px -4px 4px -2px rgba(0,0,0,0.1)',
        backgroundColor: '#F0F8FF'
    },

    sideMenu: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        left: '0px',
        width: '263px',
        height: '100%',
        backgroundColor: '#d8dcf1'
    },
    searchInput: {
        opacity: '0.8',
        padding: `0px ${theme.spacing(1)}px`,
        fontSize: '0.8rem',
        borderRadius: '5px',
        backgroundColor: '#d8dcf1',
        '&:hover': {
            backgroundColor: '#fff'
        },
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1)
        }
    },
    center: {
        margin: 'auto',
        width: '50%',
    },
    buyNowButton: {
        alignItems: "center",
        appearance: "none",
        backgroundImage: "radial-gradient(100% 100% at 100% 0, #10eb03 0, #275d1d 100%)",
        border: 0,
        borderRadius: 6,
        boxShadow:
            "rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset",
        boxSizing: "border-box",
        color: "#fff",
        cursor: "pointer",
        display: "inline-flex",
        fontFamily: "JetBrains Mono, monospace",
        height: 42,
        justifyContent: "center",
        lineHeight: 1,
        listStyle: "none",
        overflow: "hidden",
        paddingLeft: 16,
        paddingRight: 16,
        position: "relative",
        textAlign: "left",
        textDecoration: "none",
        transition: "box-shadow .15s, transform .15s",
        userSelect: "none",
        WebkitUserSelect: "none",
        touchAction: "manipulation",
        whiteSpace: "nowrap",
        willChange: "box-shadow, transform",
        fontSize: 16,
        "&:focus": {
            /*   boxShadow: "#3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset", */
        },
        "&:hover": {
            /*    boxShadow: "rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset", */
            transform: "translateY(-2px)",
        },
        "&:active": {
            /*    boxShadow: "#3c4fe0 0 3px 7px inset", */
            transform: "translateY(2px)",
        },
    },
    viewMoreLessButton: {
        alignItems: "center",
        appearance: "none",
        backgroundColor: "transparent",
        borderRadius: 4,
        borderWidth: 0,
        boxShadow: "rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset",
        boxSizing: "border-box",
        color: "#36395A",
        cursor: "pointer",
        display: "inline-flex",
        fontFamily: "'JetBrains Mono', monospace",
        height: 48,
        justifyContent: "center",
        lineHeight: 1,
        listStyle: "none",
        overflow: "hidden",
        paddingLeft: 16,
        paddingRight: 16,
        position: "relative",
        textAlign: "left",
        textDecoration: "none",
        transition: "box-shadow .15s, transform .15s",
        userSelect: "none",
        WebkitUserSelect: "none",
        touchAction: "manipulation",
        whiteSpace: "nowrap",
        willChange: "box-shadow, transform",
        fontSize: 18,
        "&:focus": {
            boxShadow: "#D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset",
        },
        "&:hover": {
            boxShadow: "rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset",
            transform: "translateY(-2px)",
        },
        "&:active": {
            boxShadow: "#D6D6E7 0 3px 7px inset",
            transform: "translateY(2px)",
        },
    },
    nextprevButton: {
        alignItems: "center",
        appearance: "none",
        backgroundImage: "radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%)",
        border: 0,
        borderRadius: 6,
        boxShadow:
            "rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset",
        boxSizing: "border-box",
        color: "#fff",
        cursor: "pointer",
        display: "inline-flex",
        fontFamily: "JetBrains Mono, monospace",
        height: 52,
        justifyContent: "center",
        lineHeight: 1,
        listStyle: "none",
        overflow: "hidden",
        paddingLeft: 16,
        paddingRight: 16,
        position: "relative",
        textAlign: "left",
        textDecoration: "none",
        transition: "box-shadow .15s, transform .15s",
        userSelect: "none",
        WebkitUserSelect: "none",
        touchAction: "manipulation",
        whiteSpace: "nowrap",
        willChange: "box-shadow, transform",
        fontSize: 28,
        "&:focus": {
            boxShadow: "rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
            transform: "translateY(-2px)",
        },
        "&:hover": {
            boxShadow: "rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
            transform: "translateY(-2px)",
        },
        "&:active": {
            boxShadow: "#3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
            transform: "translateY(2px)",
        },

    },

    mobileNextPrevButton: {
        alignItems: "center",
        appearance: "none",
        backgroundImage: "transparent",
        border: 0,
        borderRadius: 6,
        boxShadow:
            "rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset",
        boxSizing: "border-box",
        color: "#000",
        cursor: "pointer",
        display: "inline-flex",
        fontFamily: "JetBrains Mono, monospace",
        height: 52,
        justifyContent: "center",
        lineHeight: 1,
        listStyle: "none",
        overflow: "hidden",
        paddingLeft: 16,
        paddingRight: 16,
        position: "relative",
        textAlign: "left",
        textDecoration: "none",
        transition: "box-shadow .15s, transform .15s",
        userSelect: "none",
        WebkitUserSelect: "none",
        touchAction: "manipulation",
        whiteSpace: "nowrap",
        willChange: "box-shadow, transform",
        fontSize: 28,
        "&:focus": {
            boxShadow: "rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
            transform: "translateY(-2px)",
        },
        "&:hover": {
            boxShadow: "rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
            transform: "translateY(-2px)",
        },
        "&:active": {
            boxShadow: "#3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
            transform: "translateY(2px)",
        },

    },
    SettingRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,

    },
    SettingsTabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        borderTop: '1px solid #C8E6C9'
    },
    SettingsTabContainerSettings: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px'
    },
    SettingTabName: {
        marginLeft: '10px',
        fontWeight: '500',
        fontSize: '14px',
        marginRight: '10px',
    },
    SettingPullLeft: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '0px !important',
        padding: '0px !important',
        marginLeft: '10px !important',
    },
    ellipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '100px'
    },
    dashedBorderContainer: {
        position: 'relative',
    },

    //dashedBorderContainer: {
    //    position: 'relative',
    //    '&::before, &::after': {
    //        content: '" "',
    //        position: 'absolute',
    //        width: '90%',
    //        height: 0,
    //        borderTop: '2px dashed #a5a2a2',
    //        top: 0,
    //        bottom: 0,
    //    },
    //    '&::before': {
    //        top: 0,
    //    },
    //    '&::after': {
    //        bottom: 0,
    //    },
    //},
    wave: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(to bottom, rgba(1, 153, 232, 0.1), rgba(1, 153, 232, 0.2))',
        height: '40px',
        position: 'relative',
        marginBottom: '20px',
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            left: '0',
            bottom: '0',
            right: '0',
            backgroundRepeat: 'repeat-x',
        },
        '&::before': {
            height: '10px',
            backgroundSize: '20px 20px',
            backgroundImage: 'radial-gradient(circle at 10px -5px, transparent 12px, white 13px)',
        },
        '&::after': {
            height: '15px',
            backgroundSize: '40px 20px',
            backgroundImage: 'radial-gradient(circle at 10px 15px, white 12px, transparent 13px)',
        },

    },

    // dashboard change 

    rootReport: {
        borderRadius: '10px',
        border: '1px solid #205BD4',
        padding: '8px',
        textTransform: 'none',
        color: '#fff',
        cursor: 'pointer',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        background: 'rgba(32,91,212,.83)',
        // background: ' linear-gradient(270deg, rgba(2,0,36,0.1) 0%, rgba(9,90,121,0.2) 0%, rgba(0,0,0,1) 33%)',
        '&:hover': {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.6)',
            transition: 'box-shadow 0.3s ease-in-out',
            background: 'rgba(32,91,212,1)',
        },
        // margin: theme.spacing(1),
        width: '170px',
    },
    iconReport: {
        marginLeft: theme.spacing(1),
        transition: 'transform 0.3s ease-in-out',
    },
    buttonReport: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
    },
    buttonReportHover: {
        '&:hover $iconReport': {
            transform: 'translateX(5px)',
        },
    },
    containerReport: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '8px',
        borderRadius: '5px',
    },
    heading: {
        textAlign: 'left',
        color: '#3f51b5',
        marginBottom: '-11px',

    },
    linkButtonReport: {
        textDecoration: 'underline',
        color: '#3f51b5',
        cursor: 'pointer',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: 'right',
        fontSize: '12px',
    },
    buttonTabParty: {
        margin: theme.spacing(0.5),
        borderRadius: '4px',
        textTransform: 'none',
        fontWeight: 600,
        transition: 'all 0.3s ease',
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.action.active,
        },
    },
    activeButtonTabParty: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            borderColor: theme.palette.secondary.dark,
        },
    },
    searchContainerParty: {
        display: 'flex',
        alignItems: 'center',
    },
    listItemParty: {
        padding: theme.spacing(1),
    },
    textFieldParty: {
        marginLeft: theme.spacing(1),
        transition: 'width 300ms ease-in-out',
        width: 0,
        '&.entering, &.entered': {
            width: '150px', // Final width when expanded
        },
        '&.exiting, &.exited': {
            width: 0, // Width when collapsed
        },
    },
    tableContainer: {
        borderRadius: 10,
        boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
        overflow: 'hidden',
    },
    tableHeader: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        color: '#333',
    },
    tableCell: {
        padding: '10px 20px',
        fontSize: '14px',
        textAlign: 'center',
    },
    inputField: {
        width: '50px',
    },
    actionButton: {
        backgroundColor: '#ffcccc',
        borderRadius: '50%',
        minWidth: '40px',
        minHeight: '40px',
        padding: 0,
        '&:hover': {
            backgroundColor: '#ff9999',
        },
    },
    barCodeButton: {
        backgroundColor: '#76ab43',
        borderRadius: '50%',
        minWidth: '40px',
        minHeight: '40px',
        padding: 0,
        '&:hover': {
            backgroundColor: '#a8dd74',
        },
    },
    deleteIcon: {
        color: '#ff0000',
    },
    mediaImage: {
        width: '100%',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    mediaVideo: {
        width: '100%',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    previewTitle: {
        marginBottom: theme.spacing(2),
        fontWeight: 600,
    },

}))

export default useStyles;

